import feed from '../../components/feed';

export default class HomeClass {
  constructor() {
    this.$instagramFeed = $('.instagram-feed');
    this.url = this.$instagramFeed.data('url');
  }

  init() {
    if (this.$instagramFeed.length > 0) {
      return feed.get(this.url);
    }
  }
}
