import Instafeed from 'instafeed.js';

const feed = {
  get: (url) => {
    if (url) {
      $.ajax({
        type: 'get',
        dataType: 'json',
        url: url,
        success: function (response) {
          const feed = new Instafeed({
            template:
              '<a href="{{link}}">' +
              '<figure>' +
              '<picture>' +
              '<source srcset="{{image}}" media="(min-width: 768px)" />' +
              '<source srcset="{{image}}" media="(min-width: 1024px)" />' +
              '<img title="{{caption}}" alt="{{caption}}" src="{{image}}" />' +
              '</picture>' +
              '</figure>' +
              '</a>',
            accessToken: response.Token
          });

          feed.run();
        }
      });
    }
  }
};

export default feed;
