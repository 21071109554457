import { visibleQuantityProductTile } from '@assets/utils/utils';

const math = {
  visibleQuantityProductTile: visibleQuantityProductTile(),
  update: (inputCounter, maxvalue) => {
    $(inputCounter).each((index, el) => {
      const $this = $(el);
      const intvalue = parseInt($this.val());
      const uuid = $this.data('uuid');
      const item = $(`.input-quantity[data-uuid="${uuid}"]`).not($this);
      const isExperienceCounterItem = !!(math.visibleQuantityProductTile && item.hasClass('input-quantity__expereince'));
      const isExperienceCounter = !!(math.visibleQuantityProductTile && $this.hasClass('input-quantity__expereince'));

      if (intvalue < 1 || isNaN(intvalue)) {
        $this.val(1);
      }

      if (intvalue === 1) {
        if (!isExperienceCounter) {
          $this.parent().find('.decrease').addClass('disabled');
        }

        if (item.length > 0 && !isExperienceCounterItem) {
          item.parent().find('.decrease').addClass('disabled');
        }
      } else {
        $this.parent().find('.decrease').removeClass('disabled');
        if (item.length > 0) {
          item.parent().find('.decrease').removeClass('disabled');
        }
      }

      if ($this.attr('data-value-max')) {
        maxvalue = parseInt($this.attr('data-value-max'));
      } else if (isNaN(parseInt($this.attr('data-value-max')))) {
        return maxvalue;
      } else {
        return maxvalue;
      }

      if (intvalue >= maxvalue) {
        $this.parent().find('.increase').addClass('disabled');

        if (item.length > 0) {
          item.parent().find('.increase').addClass('disabled');
        }
      } else {
        $this.parent().find('.increase').removeClass('disabled');

        if (item.length > 0) {
          item.parent().find('.increase').removeClass('disabled');
        }
      }

      if (intvalue > maxvalue) {
        $this.val(maxvalue);
      }
    });
  }
};

export default math;
