import Plyr from 'plyr';
import isMobile from 'ismobilejs';
import computedStyle from '../utils/computedStyle';

export default class Video {
  init() {
    const mobile = isMobile(window.navigator).any && $(window).innerWidth() <= parseInt(computedStyle.get('--breakpoint-md'));
    $('.video-tile-hero').each((index, element) => {
      const config = mobile ? $(element).attr('data-plyr-sm-config') : $(element).attr('data-plyr-lg-config');
      const id = mobile ? $(element).attr('data-plyr-sm-embed-id') : $(element).attr('data-plyr-lg-embed-id');
      $(element).attr('data-plyr-config', config).attr('data-plyr-embed-id', id);
    });

    Plyr.setup('.video-tile-hero');
  }
}
