export default class Focus {
    setTabNextFocus(focusParams) {
        const KEYCODE_TAB = 9;
        const isTabPressed = (focusParams.event.key === 'Tab' || focusParams.event.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
            return;
        }

        const firstFocusableEl = $(`${focusParams.containerSelector} ${focusParams.firstElementSelector}`);
        let lastFocusableEl = $(`${focusParams.containerSelector} ${focusParams.lastElementSelector}`);

        if ($(`${focusParams.containerSelector} ${focusParams.lastElementSelector}`).is(':disabled')) {
            lastFocusableEl = $(`${focusParams.containerSelector} ${focusParams.nextToLastElementSelector}`);
            if ($('.product-quickview.product-set').length > 0) {
                const linkElements = $(`${focusParams.containerSelector} a#fa-link.share-icons`);
                lastFocusableEl = linkElements[linkElements.length - 1];
            }
        }

        if (focusParams.event.shiftKey) /* shift + tab */ {
            if ($(':focus').is(firstFocusableEl)) {
                lastFocusableEl.focus();
                focusParams.event.preventDefault();
            }
        } else /* tab */ {
            if ($(':focus').is(lastFocusableEl)) { // eslint-disable-line
                firstFocusableEl.focus();
                focusParams.event.preventDefault();
            }
        }
    }
}
